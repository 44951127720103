import { ButtonBanner } from './button';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  Filter,
  DateField,
  BooleanInput,
  required,
  BulkDeleteWithUndoButton,
  Toolbar,
  maxLength,
  RecordContext,
  DateInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { EnabledField } from '../../fields/enabled-field';
import { useContext, useState } from 'react';
import { FormType, SortingDirection } from '../../variables';
import { NativeBanner } from './native';
import { StaticBanner } from './static';
import { HeaderTextBanner } from './header-text';
import { SearchFieldBanner } from './search-field';
import { TrendingCoinAdSpotBanner } from './trending-coin-ad-spot';
import { WalletBanner } from './wallet';
import { PortfolioBanner } from './portfolio';
import { ButtonAppBanner } from './button_app';
import { HeaderAppBanner } from './header_app';
import { MarketsBanner } from './markets';
import { ArbitrageBanner } from './arbitrage';
import { ArrayInput, FormDataConsumer } from 'react-admin';
import { ExchangesBanner } from './exchanges';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { AdminSaveButton } from '../../buttons/save-btn';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import { getRole } from '../../utils/get-role';
import { UserRole } from '../../variables/user-role';
import { AuditBanner } from './audit';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { startCase } from '../../utils/string';
import { LaunchpadsBanner } from './launchpads';
import { CrowdsalesBanner } from './crowdsales';
import { TagsBanner } from './tags';
import { FundraisingBanner } from './fundraising';
import { HeaderGlobalBanner } from './header-global';
import { getCountries } from '../../api-service';
import { NewsBanner } from './news';
import { FundingRoundsBanner } from './funding-rounds';
import { FundsBanner } from './funds';

enum Banners {
  NATIVE = 'native',
  STATIC = 'static',
  BUTTON = 'button',
  HEADER_TEXT = 'header text',
  SEARCH_FIELD = 'search field',
  TRENDING_COIN_SPOT = 'trending coin ad spot',
  WALLET = 'wallet',
  PORTFOLIO = 'portfolio',
  HEADER_APP = 'header app',
  BUTTON_APP = 'button app',
  MARKETS = 'markets',
  ARBITRAGE = 'arbitrage',
  EXCHANGES = 'exchanges',
  AUDIT = 'audit',
  LAUNCHPADS = 'launchpads',
  CROWDSALES = 'crowdsales',
  TAGS = 'tags',
  FUNDRAISING = 'fundraising',
  HEADER_GLOBAL = 'header global',
  NEWS = 'news',
  FUNDING_ROUNDS = 'funding rounds',
  FUNDS = 'funds',
}

const getFormContent = (type: Banners, props) => {
  switch (type) {
    case Banners.NATIVE:
      return <NativeBanner />;
    case Banners.STATIC:
      return <StaticBanner />;
    case Banners.BUTTON:
      return <ButtonBanner />;
    case Banners.HEADER_TEXT:
      return <HeaderTextBanner />;
    case Banners.SEARCH_FIELD:
      return <SearchFieldBanner />;
    case Banners.TRENDING_COIN_SPOT:
      return <TrendingCoinAdSpotBanner />;
    case Banners.WALLET:
      return <WalletBanner />;
    case Banners.PORTFOLIO:
      return <PortfolioBanner />;
    case Banners.HEADER_APP:
      return <HeaderAppBanner />;
    case Banners.BUTTON_APP:
      return <ButtonAppBanner />;
    case Banners.MARKETS:
      return <MarketsBanner />;
    case Banners.ARBITRAGE:
      return <ArbitrageBanner />;
    case Banners.EXCHANGES:
      return <ExchangesBanner />;
    case Banners.AUDIT:
      return <AuditBanner />;
    case Banners.LAUNCHPADS:
      return <LaunchpadsBanner />;
    case Banners.CROWDSALES:
      return <CrowdsalesBanner />;
    case Banners.TAGS:
      return <TagsBanner {...props} />;
    case Banners.FUNDRAISING:
      return <FundraisingBanner {...props} />;
    case Banners.HEADER_GLOBAL:
      return <HeaderGlobalBanner {...props} />;
    case Banners.NEWS:
      return <NewsBanner {...props} />;
    case Banners.FUNDING_ROUNDS:
      return <FundingRoundsBanner {...props} />;
    case Banners.FUNDS:
      return <FundsBanner {...props} />;
    default:
      return null;
  }
};

const mobileAppTypes = [Banners.BUTTON_APP, Banners.HEADER_APP];

const bannersWithoutPaidSwitcher = [
  Banners.MARKETS,
  Banners.ARBITRAGE,
  Banners.LAUNCHPADS,
  Banners.CROWDSALES,
  Banners.TAGS,
  Banners.FUNDRAISING,
  Banners.HEADER_GLOBAL,
  Banners.FUNDING_ROUNDS,
  Banners.FUNDS,
];

const bannersWithDescriptions = [
  Banners.ARBITRAGE,
  Banners.MARKETS,
  Banners.EXCHANGES,
  Banners.CROWDSALES,
  Banners.LAUNCHPADS,
  Banners.TAGS,
  Banners.FUNDRAISING,
  Banners.HEADER_GLOBAL,
  Banners.FUNDING_ROUNDS,
  Banners.FUNDS,
];

const bannersWithoutCalendars = [Banners.TAGS, Banners.FUNDRAISING];

const defaultBannerType = Banners.NATIVE;

const SHORT_TEXT_VALIDATION = {
  [Banners.TAGS]: [maxLength(60)],
  [Banners.HEADER_GLOBAL]: [maxLength(60)],
  [Banners.FUNDRAISING]: [maxLength(50)],
};

const SHORT_TEXT_HELPER_TEXT = {
  [Banners.TAGS]: 'Максимальная длина 60 символов',
  [Banners.HEADER_GLOBAL]: 'Максимальная длина 60 символов',
  [Banners.FUNDRAISING]: 'Максимальная длина 50 символов',
};

const Form = (props) => {
  const [isApprovedCountriesChoose, setIsApprovedCountriesChoose] = useState(false);
  const [type, setType] = useState(defaultBannerType);
  const isAccountManager = getRole() === UserRole.ACCOUNT_MANAGER;

  return (
    <SimpleForm
      {...props}
      toolbar={
        <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
          <AdminSaveButton disabled={isAccountManager} />
          <AdminDeleteButton disabled={isAccountManager} />
        </Toolbar>
      }
    >
      <SelectInput
        format={(type: Banners) => {
          setType(type);
          return type;
        }}
        source="bannerType"
        label="Type"
        value={defaultBannerType}
        choices={Object.values(Banners).map((bannerType) => ({ id: bannerType, name: startCase(bannerType) }))}
        defaultValue=""
        validate={required()}
      />
      <TextInput source="clientName" validate={required()} />
      {!mobileAppTypes.includes(type) &&
        type !== Banners.MARKETS &&
        type !== Banners.ARBITRAGE &&
        type !== Banners.FUNDRAISING &&
        type !== Banners.TAGS &&
        type !== Banners.NEWS &&
        type !== Banners.HEADER_GLOBAL &&
        type !== Banners.FUNDING_ROUNDS &&
        type !== Banners.FUNDS && (
          <>
            <BooleanInput source="showOnDesktop" defaultValue={true} />
            <BooleanInput source="showOnMobile" defaultValue={true} />
          </>
        )}

      {getFormContent(type, props)}

      <div>
        {bannersWithDescriptions.includes(type) && (
          <FormDataConsumer>
            {({ formData }) => {
              const translationsItems = formData.translations || [{ lang: 'en' }, { lang: 'ru' }];

              return (
                <ArrayInput source="translations" defaultValue={translationsItems}>
                  <ArrayInputIterator
                    disableAdd
                    disableRemove
                    disableReordering
                    getItemLabel={(index) =>
                      Array.isArray(formData.translations) ? formData.translations[index]?.lang : ''
                    }
                  >
                    <TextInput
                      source="short_text"
                      label="Short text"
                      validate={SHORT_TEXT_VALIDATION[type] ? SHORT_TEXT_VALIDATION[type] : [required(), maxLength(80)]}
                      helperText={
                        SHORT_TEXT_HELPER_TEXT[type] || 'Рекомендуемая длина 60 символов (Максимальная - 80 символов)'
                      }
                    />
                    <TextInput
                      source="button_title"
                      label="Button title"
                      // toolbar={quillToolbar}
                      validate={type === Banners.TAGS ? [maxLength(20)] : [required(), maxLength(20)]}
                    />

                    {type === Banners.LAUNCHPADS || type === Banners.CROWDSALES ? (
                      <TextInput
                        source="second_button_title"
                        label="Button 2 title"
                        validate={[required(), maxLength(20)]}
                      />
                    ) : null}
                  </ArrayInputIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        )}
      </div>

      <BooleanInput source="enabled" />
      {!bannersWithoutPaidSwitcher.includes(type) && <BooleanInput source="paid" />}

      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
        <ReferenceArrayInput
          source={isApprovedCountriesChoose ? 'allowedCountryIds' : 'forbiddenCountryIds'}
          reference="countries"
          sort={{ field: 'id', order: SortingDirection.ASC }}
        >
          <AutocompleteArrayInput label="Countries" optionText="name" optionValue="id" />
        </ReferenceArrayInput>

        <div>
          <input
            style={{ marginRight: 8 }}
            type="checkbox"
            checked={isApprovedCountriesChoose}
            onChange={() => setIsApprovedCountriesChoose(!isApprovedCountriesChoose)}
          />
          {isApprovedCountriesChoose ? 'Approved' : 'Forbidden'}
        </div>
      </div>
      <label>
        * If disabled then selected countries are excluded from the advertisement; if enabled then only selected
        countries will see advertisement.
      </label>
      <br />

      <DateInput
        source="startAt"
        label="Start Date"
        validate={bannersWithoutCalendars.includes(type) ? [] : required()}
      />
      <DateInput source="endAt" label="End Date" validate={bannersWithoutCalendars.includes(type) ? [] : required()} />
    </SimpleForm>
  );
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Client" source="clientName" alwaysOn={true} />
    <SelectInput
      alwaysOn={true}
      source="bannerType"
      label="Type"
      value={defaultBannerType}
      choices={Object.values(Banners).map((bannerType) => ({ id: bannerType, name: startCase(bannerType) }))}
    />
    <BooleanInput label="Enabled" source="enabled" alwaysOn={true} />
  </Filter>
);

export const BannerList = () => {
  const isAccountManager = getRole() === UserRole.ACCOUNT_MANAGER;

  return (
    <List sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<Filters />}>
      <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton disabled={getIsDisabledByRole() || isAccountManager} />}>
        <TextField source="id" />
        <EnabledField source="enabled" label="On" />
        <TextField source="clientName" />
        <TextField source="bannerType" />
        <DateField source="startAt" />
        <DateField source="endAt" />
        <EditButton />
        <AdminDeleteButton />
      </Datagrid>
    </List>
  );
};

const BannerTitle = () => {
  const context = useContext(RecordContext);

  return (
    <span>
      Banner #{context?.id}: {context?.clientName}
    </span>
  );
};

const transform = async (data) => {
  if (data.allowedCountryIds && data.allowedCountryIds.length > 0) {
    const allCountries = await getCountries();
    const forbiddenCountryIds = allCountries
      .filter(({ id }) => !data.allowedCountryIds.includes(id))
      .map(({ id }) => id);

    return { ...data, forbiddenCountryIds, allowedCountryIds: undefined };
  }

  return data;
};

export const BannerEdit = () => {
  return (
    <Edit transform={transform} title={<BannerTitle />}>
      <Form formType={FormType.EDIT} />
    </Edit>
  );
};

export const BannerCreate = () => {
  return (
    <Create transform={transform} title={<BannerTitle />}>
      <Form formType={FormType.CREATE} />
    </Create>
  );
};
