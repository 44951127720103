import { TextInput } from 'ra-ui-materialui';
import { BooleanInput, required, SelectInput } from 'react-admin';
import { IconWithPreview } from './components';
import { adTypes } from './variables';

export const FundsBanner = () => {
  return (
    <>
      <TextInput source="title" label="Title" fullWidth={true} validate={required()} />

      <BooleanInput label="Show Button" source="showButton" />
      <TextInput label="Link url" source="url" fullWidth={true} validate={required()} />

      <IconWithPreview />
      <SelectInput label="Type of AD" source="adType" choices={adTypes} />
    </>
  );
};
