import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  TextInput,
  ImageInput,
  ImageField,
  FunctionField,
  TopToolbar,
  ListButton,
  RefreshButton,
  Filter,
  CreateButton,
  Create,
  ReferenceInput,
  required,
  ReferenceArrayInput,
  BooleanInput,
  AutocompleteArrayInput,
  SelectInput,
  FormDataConsumer,
  maxValue,
  minValue,
  BulkDeleteWithUndoButton,
  useNotify,
  DateInput,
  useRecordContext,
  TabbedForm,
} from 'react-admin';
import { OpenInNew } from '@mui/icons-material';
import { getEnvs } from '../env-lib';
import { AutocompleteInput } from '../inputs/autocomplete-input';
import { FRONTEND_URL, imageInputAccept, SortingDirection } from '../variables';
import { S3ImageDir } from '../variables';
import { getRole } from '../utils/get-role';
import { UserRole } from '../variables/user-role';
import { AdminDeleteButton } from '../buttons/delete-btn';
import { DefaultToolBar } from '../toolbars/default-toolbar';
import { getIsDisabledByRole } from '../utils/get-disabled-by-role';
import { NumberInput } from '../inputs/number-input';
import { helperTexts } from '../variables/helper-texts';
import { TrimOnBlurInput } from '../inputs/trim-on-blur-input';
import { IdoPlatformShieldMap } from '../variables/ido_platform_shield';
import { TranslatableInput } from '../inputs/translatable-input';
import { TinymceEditor } from '../inputs/tinymce-editor';

const notAdmin = getRole() !== UserRole.ADMIN;

const platformTypes = [{ name: 'IDO' }, { name: 'IEO' }, { name: 'ICO' }];

const imagesBasePath = getEnvs().REACT_APP_IMAGES_BASE_URL + S3ImageDir.IDO_PLATFORMS;

const handleFailure = (notify) => (error) => {
  let errorMessage = error.toString();
  if (errorMessage.includes('TypeError: Cannot read properties of undefined')) {
    errorMessage =
      'Данный нативный токен уже используется в другой платформе. Пожалуйста проверьте корректность указанного токена.';
  }
  notify(errorMessage, { type: 'warning' });
};

const IdoPlatformsTitle: any = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <span>
      Launchpads {record ? `"${record.name}"` : ''}
      &nbsp;
      <a rel="nofollow noopener noreferrer" target="_blank" href={`${FRONTEND_URL}/fundraising-platform/${record.key}`}>
        <OpenInNew />
      </a>
    </span>
  );
};

const IdoPlatformsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="q" alwaysOn={true} />
  </Filter>
);

const EditActions = ({ data, resource }: any) => (
  <TopToolbar>
    <CreateButton />
    <ListButton />
    <AdminDeleteButton record={data} resource={resource} />
    <RefreshButton />
  </TopToolbar>
);

const ReferenceInputNative = (props) => (
  <ReferenceInput
    label="Native Token"
    source="nativeCoinId"
    reference="coins"
    perPage={10}
    sort={{ field: 'id', order: SortingDirection.ASC }}
    validate={props.validate}
    allowEmpty={props.allowEmpty}
  >
    {props.children}
  </ReferenceInput>
);

const MinTokensToParticipate = (props) => (
  <NumberInput
    disabled={props.disabled}
    source="minAmountToParticipate"
    validate={[minValue(0), maxValue(1000000000)]}
    label="Min Tokens to Participate"
  />
);

const prepareData = (data) => {
  const updatedData = { ...data };
  if (updatedData.type === 'ICO') {
    updatedData.minAmountToParticipate = undefined;
    updatedData.nativeCoinId = undefined;
  }

  if (updatedData.type !== 'IDO') {
    updatedData.platformIds = undefined;
  }

  if (!updatedData.isSponsored) {
    updatedData.ido_platform_shield = null;
  }

  if (!updatedData.ido_platform_shield?.isTrending) {
    updatedData.trending_widget = null;
  }

  return updatedData;
};

const getForm = (type: 'edit' | 'create') => {
  return (
    <TabbedForm toolbar={<DefaultToolBar />}>
      <TabbedForm.Tab label="summary">
        {type === 'edit' ? <TextInput label="Key" source="key" helperText={helperTexts.keyInputValidation} /> : null}
        <FormDataConsumer>
          {({ formData }) => <TrimOnBlurInput disabled={!formData.type} source="name" validate={[required()]} />}
        </FormDataConsumer>

        <DateInput source="foundationDate" label="Year of Foundation" validate={[required()]} />

        <SelectInput
          disabled={notAdmin && type === 'edit'}
          choices={platformTypes}
          source="type"
          validate={[required()]}
          optionValue="name"
          helperText="Types can be created by either an Admin or a Super Manager, but can only be changed by an Admin."
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.type === 'ICO') {
              return null;
            }

            return (
              <>
                {formData.type !== 'IEO' && (
                  <ReferenceInputNative validate={[required()]} {...rest}>
                    <AutocompleteInput optionText="fullName" disabled={!formData.type} />
                  </ReferenceInputNative>
                )}

                {formData.type === 'IEO' && (
                  <ReferenceInputNative {...rest}>
                    <AutocompleteInput optionText="fullName" />
                  </ReferenceInputNative>
                )}
              </>
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.type !== 'ICO' && (
                <MinTokensToParticipate disabled={formData.type !== 'IEO' && !formData.type} />
              )}
            </>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => (
            <TextInput multiline={true} label="Notification" source="notification" disabled={!formData.type} />
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) =>
            formData.type === 'IDO' ? (
              <ReferenceArrayInput
                source="platformIds"
                reference="token-platforms"
                sort={{ field: 'name', order: SortingDirection.ASC }}
              >
                <AutocompleteArrayInput optionText="name" label="Token Platforms" />
              </ReferenceArrayInput>
            ) : null
          }
        </FormDataConsumer>

        <FunctionField
          label="Image"
          render={(record) =>
            record.imageName ? (
              <img src={imagesBasePath + record.imageName} alt="image" style={{ maxHeight: '170px' }} />
            ) : null
          }
        />
        <ImageInput source="image" label="Image" accept={imageInputAccept}>
          <ImageField source="src" title="Image" />
        </ImageInput>
        <p>Maximum size is 1MB</p>

        <TranslatableInput source="locales">
          <TinymceEditor source="description" />
        </TranslatableInput>

        <BooleanInput label="Enabled" source="enabled" />
      </TabbedForm.Tab>

      <TabbedForm.Tab label="links">
        <ReferenceArrayInput source="link_ids" reference="links" sort={{ field: 'id', order: SortingDirection.DESC }}>
          <AutocompleteArrayInput filterToQuery={(q) => ({ q_value: q })} optionText="value" />
        </ReferenceArrayInput>
      </TabbedForm.Tab>

      <TabbedForm.Tab label="sponsored">
        <BooleanInput label="Exclude from Hot" source="exclude_from_hot" />
        <BooleanInput label="Exclude from Trending" source="exclude_from_trending" />
        <BooleanInput label="Sponsored" source="isSponsored" />

        <FormDataConsumer>
          {({ formData }) =>
            formData.isSponsored ? (
              <>
                <SelectInput
                  source="ido_platform_shield.shield"
                  label="Shield"
                  choices={Object.keys(IdoPlatformShieldMap).map((key) => ({
                    id: key,
                    name: IdoPlatformShieldMap[key],
                  }))}
                />

                <NumberInput source="ido_platform_shield.priority" label="#" min={0} />
                <DateInput source="ido_platform_shield.start_date" label="Start Date" />
                <DateInput source="ido_platform_shield.end_date" label="End Date" />
              </>
            ) : null
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.isSponsored && formData.ido_platform_shield?.shield === 'hot' ? (
              <BooleanInput source="ido_platform_shield.isTrending" label="Trending" />
            ) : null
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.ido_platform_shield?.isTrending ? (
              <>
                <SelectInput
                  source="trending_widget.priority"
                  label="Priority"
                  choices={[1, 2, 3].map((key) => ({
                    id: key,
                    name: key,
                  }))}
                />
                <BooleanInput source="trending_widget.show_ad" label="Show Ad Shield" />
              </>
            ) : null
          }
        </FormDataConsumer>
      </TabbedForm.Tab>

      <TabbedForm.Tab label="rules">
        <TranslatableInput source="locales">
          <TinymceEditor source="rules" />
        </TranslatableInput>
      </TabbedForm.Tab>

    </TabbedForm>
  );
};

export const IdoPlatformsEdit = (props) => {
  const notify = useNotify();
  return (
    <Edit
      actions={<EditActions />}
      title={<IdoPlatformsTitle />}
      {...props}
      transform={prepareData}
      onFailure={handleFailure(notify)}
    >
      {getForm('edit')}
    </Edit>
  );
};

export const IdoPlatformsList = (props) => (
  <List {...props} sort={{ field: 'id', order: SortingDirection.ASC }} perPage={50} filters={<IdoPlatformsFilter />}>
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const IdoPlatformCreate = (props) => {
  const notify = useNotify();
  return (
    <Create {...props} transform={prepareData} onFailure={handleFailure(notify)}>
      {getForm('create')}
    </Create>
  );
};
